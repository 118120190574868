<template>
  <div class="verify-email">
    <div class="verify-email__wrapper">
      <img
        src="@/assets/images/logo.svg"
        alt="Geor Company SAC"
        class="img-fluid"
      />

      <h5>Espere un momento, estamos validando su cuenta...</h5>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        email: null,
        code: null,
      },
    };
  },
  mounted() {
    const query = { ...this.$route.query };
    this.form.email = query.email;
    this.form.code = query.code;

    if (this.$auth.check()) {
      this.onSubmit();
    } else {
      this.$router.push({ name: "LoginEmail" });
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: (state) => state.user,
    }),
  },
  methods: {
    onSubmit() {
      const urlBase = this.$axios.defaults.baseURL;
      this.$axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE;
      this.$axios.post("auth/verify/email", this.form).then(() => {
        this.$axios.defaults.baseURL = urlBase;
        const { user } = this;
        user.isValidated = true;
        this.$auth.saveUser(user);
        this.$router.push({ name: "Dashboard" });
      });
    },
  },
};
</script>
